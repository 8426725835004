<template>
  <div class="provider-preview">
    <div class="payment-link">
      <div class="payment-link__container">
        <div class="payment-link__preview">
          <div class="payment-link__preview__container">
            <payment-link-flow :facility="facility" />
          </div>
        </div>
        <div class="payment-link__opt-in-section">
          <h4 class="font-heading-30 mb-2">
            Opt-in to enable one-click payment requests to families!
          </h4>
          <p class="mb-3">
            Receive payments for everything from deposits to tuition.
            (Transactions are subject to a 2% fee)
          </p>
          <div>
            <system-button
                style-type="primary"
                @click="optin"
                class="w-100 mb-3"
            >
              Opt-in
            </system-button>
            <system-button
                style-type="secondary"
                @click="goBack"
                class="w-100"
            >
              Go back
            </system-button>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

import SystemButton from '@components/buttons/system-button.vue';
import PaymentLinkFlow from '@care-provider/components/payments/payment-link-flow.vue';

export default {
  components: {
    PaymentLinkFlow,
    SystemButton,
  },
  props: {
    facility: Object,
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    async optin() {
      const response = await this.$store.dispatch('payments/confirmNewFeeStructure', { providerId: this.$store.state.user.current.providerId });
      if (response?.success) {
        this.$router.go();
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.provider-preview {
  max-width: 1060px;
  border-radius: 36px;
  margin: 0 auto;
  background-color: white;
  padding: 32px 32px 48px;
}

.font-heading-30 {
  max-width: none;
  line-height: 40px;
}

.payment-link {
  &__container {
    @media screen and (min-width: 992px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(auto-fit, minmax(50px, auto));
      grid-gap: calc(var(--grid-unit) * 5) calc(var(--grid-unit) * 4);
    }
  }

  &__preview {
    grid-area: 1 / 1 / 3 / 2;

    &__container {
      border-radius: calc(var(--grid-unit) * 4);
      background: var(--viridian-0);
      padding: calc(var(--grid-unit) * 4);
    }
  }

  &__opt-in-section {
    grid-area: 2 / 2 / 3 / 3;
    padding-bottom: calc(var(--grid-unit) * 4);
    margin-bottom: calc(var(--grid-unit) * 4);
    border-bottom: 1px solid var(--gray-15);
    margin-top: 15%;

    @media screen and (min-width: 992px) {
      padding-bottom: 0;
      margin-bottom: 0;
      border-bottom: 0;
    }
  }

  &__link {
    border-radius: var(--grid-unit);
    border: 1px solid var(--gray-20);
    line-height: 43px;
    padding: 0 var(--grid-unit);
    background: #fff;
    width: 100%;
  }
}
</style>
