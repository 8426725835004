<template>
  <main class="pay_me_flow">
    <div class="pay_me_flow__content">
      <header class="pay_me_flow__header">
        <div><i class="fal fa-usd-circle"></i> SEND PAYMENT</div>
        <steps :current-step="1" :total-steps="3" />
      </header>

      <section class="pay_me_flow__body">
        <simple-facility-card
          :facility-data="facility"
          :is-summary="false"
          :is-claimable="false"
          :is-editable="false"
          class="facility-card"
        />

        <p v-if="showEmailInput"
           class="login-text">Have a Kinside account?
          <a href="#">Sign in</a>
        </p>
        <k-form-field
            v-if="showEmailInput"
            label="Email"
            class="form-field"
            :rules="{email: true, required: true}"
        >
          <k-input
              class="w-100"
              placeholder="Enter your email address..."
              disable
          >
          </k-input>
        </k-form-field>

        <k-form-field
            v-if="showEmailInput"
            label="Name"
            optional-label="for provider’s records"
            rules="required"
            class="k-form-field__name"
        >
          <k-input
              placeholder="First and last name..."
          />
        </k-form-field>

        <k-form-field label="How much would you like to send?">
          <currency-input
              data-cy="total-amount-input"
              rules="required"
              disabled
          />
        </k-form-field>
        <k-form-field label="What is this payment for?">
          <k-radio disabled>Deposit/registration</k-radio>
          <k-radio disabled>Tuition</k-radio>
          <k-radio class="other" disabled>Other</k-radio>
        </k-form-field>

        <hr/>
        <div class="align-center">
          <system-button :fullWidth="true" disabled>Continue</system-button>
        </div>
      </section>
    </div>
  </main>
</template>

<script>
import Steps from '@components/progress/steps.vue';
import SystemButton from '@components/buttons/system-button.vue';
import SimpleFacilityCard from '@components/simple-facility-card/simple-facility-card.vue';
import CurrencyInput from '@components/inputs/currency_input.vue';
import KFormField from '@components/forms/form_field.vue';
import KRadio from '@components/inputs/radio.vue';
import KInput from '@components/inputs/input.vue';

export default {
  name: 'pay-link-flow',
  components: {
    SimpleFacilityCard,
    Steps,
    SystemButton,
    CurrencyInput,
    KFormField,
    KRadio,
    KInput,
  },
  props: {
    facility: {
      type: Object,
      required: true,
    },
    showEmailInput: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.pay_me_flow {
  &__content {
    margin: 0;
    background: var(--gray-0);
    font-size: calc(var(--grid-unit) * 2);
    border-radius: calc(var(--grid-unit) * 4);
  }

  &__header {
    display: revert;
    color: var(--viridian-0);
    text-align: center;
    text-transform: uppercase;
    background: var(--viridian-50);
    padding: calc(var(--grid-unit) * 2);
    font-weight: 500;
    border-radius: calc(var(--grid-unit) * 4) calc(var(--grid-unit) * 4) 0 0;

    .fal {
      margin-right: var(--grid-unit);
      font-weight: 500;
    }

    > * {
      margin-bottom: 0;
    }
  }

  &__body {
    padding: calc(var(--grid-unit) * 2);

    @media screen and (min-width: 400px) {
      padding: calc(var(--grid-unit) * 3);
    }

    @media screen and (min-width: 768px) {
      padding: calc(var(--grid-unit) * 6);
      padding-bottom: calc(var(--grid-unit) * 3);
    }

    > * {
      margin-bottom: calc(var(--grid-unit) * 2);

      @media screen and (min-width: 400px) {
        margin-bottom: calc(var(--grid-unit) * 3);
      }
    }
  }
}

.login-text {
  font-size: calc(var(--grid-unit) * 2);
  font-weight: 500;
  margin-bottom:  calc(var(--grid-unit) * 2);

  a {
    text-decoration: underline;
  }
}
</style>
