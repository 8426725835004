<template>
  <div>
    <p v-if="!isAuthorized" class="login-text">
      Have a Kinside account?
      <a href="#" @click.prevent="switchToLogin">Sign in</a>
    </p>
    <div class="parent-info" v-if="guest">
      <h6 class="font-heading-10 mg-b-8">PARENT/GUARDIAN INFO</h6>
      <k-fieldset class="field-name">
        <k-form-field
          label="First name"
          rules="required"
          class="form-field"
          >
          <k-input placeholder="First name..." v-model="firstName" />
        </k-form-field>

        <k-form-field
          label="Last name"
          rules="required"
          class="form-field"
          >
          <k-input placeholder="Last name..." v-model="lastName" />
        </k-form-field>
      </k-fieldset>
      <k-form-field
        label="Email"
        class="form-field"
        :rules="{ email: true }"
      >
        <k-input
          v-model.trim="email"
          placeholder="Enter your email address..."
          disabled
        >
        </k-input>
      </k-form-field>
      <k-form-field
        label="Phone number"
        rules="required|mobileNumber"
      >
        <k-input
          rules="required"
          v-model="phoneNumber"
          placeholder="5555555555"
        />
      </k-form-field>
    </div>
    <payment-request-new-child-fields
      v-if="dependentData && dependentData.firstName"
      @child-info-changed="({ field, value }) => (dependentData[field] = value)"
      :dependent-data="dependentData"
      :hasborder=true
    />

    <div class="payment-details">
      <h1>{{ requestAmount }}</h1>
      <p class="category">{{ payMeRequest.category }}</p>
    </div>

    <recurring-payment-settings
      v-if="recurringSettings"
      :recurring-settings="recurringSettings"
      :email="email"
      :isAuthorized="isAuthorized"
      @updateRecurringSettings="updateRecurringSettings"
      @switchToLogin="switchToLogin"
      @switchToRegister="switchToRegister"
    />

    <parent-register
      :verify-email="email"
      :signup-from="'pay_me_request'"
      :verify-first-name="firstName"
      :verify-last-name="lastName"
      @navigate-to-login="switchToLogin"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getDollarFormat } from '@utils';
import KFormField from '@components/forms/form_field.vue';
import KInput from '@components/inputs/input.vue';
import RecurringPaymentSettings from '@parent/components/payments/send-payment/recurring-payment-settings.vue';
import ParentRegister from '@parent/components/register.vue';
import AuthMixin from '@profile/mixins/auth';
import PaymentRequestNewChildFields from '@components/payments/new-child-fields.vue';

export default {
  components: {
    KFormField,
    KInput,
    RecurringPaymentSettings,
    ParentRegister,
    PaymentRequestNewChildFields,
  },
  mixins: [AuthMixin],
  props: {
    providerId: {
      type: Number,
    },
    facilityId: {
      type: Number,
      required: true,
    },
    setSubmitDisabled: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      totalAmount: undefined,
      category: undefined,
      email: undefined,
      firstName: undefined,
      lastName: undefined,
      phoneNumber: undefined,
      nameChanged: false,
      user: undefined,
      recurringSettings: undefined,
      dependentData: {
        firstName: '',
        lastName: '',
        dateOfBirth: undefined,
      },
    };
  },
  computed: {
    ...mapGetters({
      facility: 'facility/facilityInfo',
      currentPayment: 'payments/currentPayment',
      parentUser: 'user/currentParent',
      payMeRequest: 'payments/payMeRequest',
    }),
    guest() {
      return !!this.parentUser?.authEtc.guest;
    },
    requestAmount() {
      return getDollarFormat(this.payMeRequest.amount);
    },
    isRecurringPayment() {
      return this.recurringSettings?.isRecurringPayment || false;
    },
  },
  watch: {
    // change to first/last name
    firstName(newValue) {
      // Check if the current value is different from the initial value
      this.nameChanged = newValue !== this.parentUser.firstName;

      if (this.firstName.length === 0) {
        this.setSubmitDisabled(true);
      } else {
        this.setSubmitDisabled(false);
      }
    },
    lastName(newValue) {
      // Check if the current value is different from the initial value
      this.nameChanged = newValue !== this.parentUser.lastName;

      if (this.lastName.length === 0) {
        this.setSubmitDisabled(true);
      } else {
        this.setSubmitDisabled(false);
      }
    },
  },
  methods: {
    updateRecurringSettings(value) {
      this.recurringSettings = value;
      this.setSubmitDisabled(!this.recurringSettings.isValid);
    },
    async onSubmit() {
      const firstName = this.firstName && this.firstName.length > 0;
      const lastName = this.lastName && this.lastName.length > 0;
      if (firstName && lastName && this.nameChanged) {
        await this.$store.dispatch('user/updateNameEmail', {
          email: this.email,
          firstName: this.firstName,
          lastName: this.lastName,
          id: this.parentUser.id,
        });
      }
      await this.$store.commit('dependents/updateDependent', { ...this.dependentData, id: this.currentPayment?.dependent.id });
      await this.$store.dispatch(
        'payments/updateCurrentPayment',
        { recurringSettings: this.isRecurringPayment ? this.recurringSettings : null },
      );
    },
  },
  mounted() {
    this.totalAmount = this.currentPayment?.amount;
    this.category = this.currentPayment?.category;
    this.recurringSettings = this.currentPayment?.recurringSettings || {};
    this.email = this.parentUser.email;
    this.firstName = this.parentUser.firstName;
    this.lastName = this.parentUser.lastName;
    this.dependentData.firstName = this.currentPayment?.dependent.firstName;
    this.dependentData.lastName = this.currentPayment?.dependent.lastName;
    this.dependentData.dateOfBirth = this.currentPayment?.dependent.dateOfBirth;

    if (this.firstName.length === 0 || this.lastName.length === 0) {
      this.setSubmitDisabled(true);
    } else {
      this.setSubmitDisabled(false);
    }
  },
};
</script>

<style lang="scss" scoped>
.payment-details {
  text-align: center;
  border: var(--border) var(--gray-80);
  border-radius: var(--radius-big);
  padding: calc(var(--grid-unit) * 4);
  margin: calc(var(--grid-unit) * 4) 0;
  width: 100%;

  .category {
    margin-top: calc(var(--grid-unit) * 2);
    font-size: var(--font-size-10);

    &::first-letter {
      text-transform: capitalize;
    }
  }
}

.login-text {
  font-size: calc(var(--grid-unit) * 2);
  font-weight: 500;
  margin-bottom: calc(var(--grid-unit) * 2);

  a {
    text-decoration: underline;
  }
}

.mg-b-8 {
  margin-bottom: calc(var(--grid-unit));
}

.kFormField + .kFormField {
  margin-top: 0 !important;
}

.field-name {
  display: flex;
  gap: 16px;
}

.parent-info {
  border: 1px solid #dbdbdb;
  border-radius: var(--radius-big);
  padding: calc(var(--grid-unit) * 3);
  margin-bottom: calc(var(--grid-unit) * 3);
}
</style>
