<template>
  <fieldset class="kFieldset">
    <legend
      v-if="$slots.legend || legend"
      class="kFieldset__legend">
      <slot name="legend">
        {{ legend }}
      </slot>
    </legend>
    <slot></slot>
  </fieldset>
</template>

<script>
export default {
  name: 'k-fieldset',
  props: {
    legend: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
  .kFieldset {
    &__legend {
      font-family: var(--serif);
      font-size: var(--font-size-30);
      color: var(--gray-80);
    }

    > *:not(:last-child) {
      margin-bottom: calc(var(--grid-unit) * 2);
    }
  }
</style>
