<template>
  <div>
    <modal
        :is-open="openModal"
        :skipClose="true"
        dialog-class="modal__dialog--reduced-width"
    >
      <section class="align-center">
        <illustration name="block5" class="mb-3 image"/>
        <section>
          <template>
            <h4 class="mb-3 font-heading-30">
              This provider has not set up payments on Kinside yet.
            </h4>
            <system-button
                style-type="primary"
                @click="goBack"
                class="w-100"
            >
              Go back
            </system-button>
          </template>
        </section>
      </section>
    </modal>
  </div>

</template>

<script>
import Modal from '@components/modal/modal.vue';
import Illustration from '@components/illustration.vue';
import SystemButton from '@components/buttons/system-button.vue';

export default {
  components: {
    Modal,
    Illustration,
    SystemButton,
  },
  props: {
    openModal: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    goBack() {
      this.$router.back();
    },
  },
};
</script>

<style lang="scss" scoped>

.image {
  margin: 0 auto;
}

.font-heading-30 {
  line-height: 40px;
}
</style>
