<template>
  <div>
    <table
        class="send-payment__summary-table"
        v-for="method in paymentMethodsFormatted"
        :key="method.name"
    >
      <tr>
        <td>{{ method.name }}</td>
        <td>{{ method.initialAmount }}</td>
      </tr>
      <tr>
        <td>Processing fee ({{ method.comissionPerc }})</td>
        <td>{{ method.comissionAmount }}</td>
      </tr>
      <tr v-if="method.discountAmount"
          class="font-body-small send-payment__summary-table__discount">
        <td>Discount</td>
        <td>{{ method.discountAmount }}</td>
      </tr>
    </table>
    <table class="send-payment__summary-table">
      <tr class="send-payment__summary-table__total">
        <td>Total amount</td>
        <td>{{ displayInDollars(totalAmountToPay) }}</td>
      </tr>

      <tr class="send-payment__summary-table__categoty">
        <td>Category</td>
        <td>{{ categories[currentPayment.category] || currentPayment.category }}</td>
      </tr>
    </table>

    <table v-if="!!recurringSettings" class="send-payment__summary-table">
      <tr>
        <td>Payment schedule</td>
        <td class="capitalize">{{ recurringSettings.frequency }}</td>
      </tr>
    </table>

    <table v-if="!!recurringSettings" class="send-payment__summary-table">
      <tr>
        <td>Last payment</td>
        <td>{{lastPaymentInfo}}</td>
      </tr>
    </table>

    <div class="align-center change-payment">
      <a href="#" @click.prevent="clickOnPrevStep();">
          <span
              class="fa-light fa-pen pd-4"
              title="Change payment info">
          </span>
        <span>Change payment info</span>
      </a>
    </div>

    <div class="textarea-container">
      <k-form-field optional-label="optional" label="Add a message for provider">
        <k-textarea
            v-model="messageToProvider"
        >
        </k-textarea>
      </k-form-field>
    </div>

    <box color="gold" class="mt-1">
      <div class="desc">
        Expected to reach the provider within 3 - 5 business days from payment sent date.
      </div>
    </box>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getDollarFormat, getPaymentMethodName, trackEvent } from '@utils';
import KFormField from '@components/forms/form_field.vue';
import KTextarea from '@components/inputs/textarea.vue';
import Box from '@components/box.vue';

export default {
  components: {
    KFormField,
    KTextarea,
    Box,
  },
  data() {
    return {
      messageToProvider: '',
      categories: {
        deposit: 'Deposit/registration',
        tuition: 'Tuition',
      },
    };
  },
  props: {
    clickOnPrevStep: {
      type: Function,
    },
  },
  computed: {
    ...mapGetters({
      currentPayment: 'payments/currentPayment',
    }),

    paymentMethodsFormatted() {
      return this.currentPayment.paymentRequestMethods.map((method) => ({
        name: getPaymentMethodName(
          this.$store.state.payments.paymentMethods[method.paymentMethodId],
        ),
        totalAmount: getDollarFormat(method.totalAmount),
        initialAmount: getDollarFormat(method.initialAmount),
        discountAmount: method.discountAmount ? getDollarFormat(method.discountAmount) : false,
        comissionAmount: getDollarFormat(method.commissionAmount),
        comissionPerc: `${Math.round((method.commissionAmount / method.initialAmount) * 1000) / 10}%`,
      }));
    },
    totalAmountToPay() {
      if (this.currentPayment.paymentRequestMethods.length) {
        return this.currentPayment.totalToBeCharged;
      }
      return this.currentPayment.amount;
    },
    recurringSettings() {
      return this.currentPayment?.recurringSettings;
    },
    lastPaymentInfo() {
      if (this.recurringSettings) {
        const dateFormat = { year: 'numeric', month: 'long', day: 'numeric' };
        return `${new Date(this.recurringSettings.end_date).toLocaleDateString('en-US', dateFormat)} (${this.recurringSettings.paymentsCount} payments)`;
      }
      return '';
    },
  },
  methods: {
    displayInDollars: getDollarFormat,
    async onSubmit() {
      if (this.recurringSettings) {
        trackEvent('onSubmit', {
          event_description: 'Payment frequency chosen - New payment',
          button_label: this.recurringSettings.frequency,
          button_location: 'checkout',
          user_type: 'parent',
        });
      }

      await this.$store.commit('payments/updateCurrentPayment', { messageToProvider: this.messageToProvider || undefined });
      if (this.currentPayment?.dependent) {
        const dependentData = this.$store.getters['dependents/dependentById'](this.currentPayment?.dependent.id);
        await this.$store.dispatch('dependents/updateDependent', dependentData);
      }
      await this.$store.dispatch('payments/addPaymentRequest', this.currentPayment);
    },
  },
};
</script>

<style lang="scss" scoped>

.send-payment {
  .font-body-small {
    color: #616161;
    margin-top: var(--grid-unit);
  }

  .textarea-container {
    margin-top: calc(var(--grid-unit) * 4);
  }

  .box .desc {
    font-size: calc(var(--grid-unit) * 1.5);
  }

  .change-payment {
    padding-top: calc(var(--grid-unit) * 3);
    font-weight: 400;
  }

  &__summary-table {
    font-size: calc(var(--grid-unit) * 2);
    width: 100%;
    margin-left: auto;
    color: var(--gray-80);
    font-weight: 400;

    tr {
      border-top: 1px solid #dbdbdb;
    }

    td {
      padding-left: calc(var(--grid-unit) * 2);
      padding-top: calc(var(--grid-unit) * 0.5);
      padding-bottom: calc(var(--grid-unit) * 0.5);

      &:last-child {
        position: relative;
        padding-right: calc(var(--grid-unit) * 2);
        text-align: right;
      }

      &::before {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
      }
    }

    &__discount {
      color: var(--periwinkle-50);

      & td:last-child::before {
        content: '-';
      }
    }

    &__total {
      > *:last-child {
        font-weight: 500;
        font-size: 21px;
      }
    }

    .capitalize {
      text-transform: capitalize;
    }
  }
}
</style>
