<template>
  <section class="payment-status page__body align-center">
    <illustration name="boyGirl" class="payment-status__image"/>
    <section>
      <h4>
        <span class="payment-status__title">{{copy.title}}</span>
      </h4>
      <template>
        <div class="initiated">
          <p class="desc">{{copy.description}}</p>
          <span>{{copy.additionalDesc}}</span>
          <p>Want to see your payment history?</p>
        </div>
        <system-button
            class="create-account"
            style-type="secondary"
            @click="switchToRegister"
        >
          View payment history
        </system-button>
        <parent-register
          :verify-email="parentUser.email"
          :signup-from="'facility_profile'"
          :verify-first-name="parentUser.firstName"
          :verify-last-name="parentUser.lastName"
          @navigate-to-login="switchToLogin"
        >
        </parent-register>
      </template>
    </section>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import Illustration from '@components/illustration.vue';
import SystemButton from '@components/buttons/system-button.vue';
import ParentRegister from '@parent/components/register.vue';
import AuthMixin from '@profile/mixins/auth';

export default {
  components: {
    Illustration,
    SystemButton,
    ParentRegister,
  },
  mixins: [AuthMixin],
  computed: {
    ...mapGetters({
      currentPayment: 'payments/currentPayment',
      facility: 'facility/facilityInfo',
      parentUser: 'user/currentParent',
    }),

    copy() {
      return {
        icon: 'fa-check-circle',
        title: `Your payment has been sent to ${this.facility.name}`,
        subtitle: 'Payment sent!',
        description: 'A receipt has been sent to',
        additionalDesc: this.parentUser.email,
      };
    },
  },
  mounted() {
    this.$router.push({ query: { is_checkout: true } });
  },
};
</script>

<style lang="scss" scoped>

.payment-status {
  padding-top: calc(var(--grid-unit) * 4);

  &::v-deep {
    & h4 {
      max-width: none;
      margin: auto;
    }

    & p {
      padding-top: calc(var(--grid-unit) * 3);
    }
  }

  &--completed {
    &::v-deep .page__content__header {
      color: var(--viridian-50);
    }
  }

  &__image {
    width: 157px;
    margin: 0 auto;
    margin-bottom: calc(var(--grid-unit) * 2);
  }

  .create-account {
    margin-top: calc(var(--grid-unit) * 2);
  }

  .initiated {
    font-size: calc(var(--grid-unit) * 2);
    font-weight: 400;

    span {
      font-weight: 500;
    }

    .desc {
      display: inline-block;
      margin-bottom:0;
    }
  }
}
</style>
