<template>
  <div>
    <box
        :color="isRecurringPayment ? 'periwinkle' : 'periwinkle-light'"
        :class="isRecurringPayment ? 'recurring-payment' : 'recurring-payment--selected'"
    >
      <k-form-field class="pb-0">
        <k-checkbox
            v-model="isRecurringPayment"
            @change="handleInputChange('isRecurringPayment')"
            :disabled="!isAuthorized"
        >
          Make this a recurring payment
          <div v-if="!isAuthorized" class="small sign-up-text">
            <a href="#" @click.prevent="onLogin">Sign in</a> or
            <a href="#" @click.prevent="onRegister">sign up</a> to use AutoPay
          </div>
        </k-checkbox>
      </k-form-field>
    </box>
    <box color="periwinkle-light" class="autopay-settings" v-if="isRecurringPayment">
      <p>Autopay settings</p>
      <div class="recurring-settings">
        <div class="row">
          <div class="label"><i class="fa-solid fa-arrows-rotate pr-1"></i>Schedule</div>
          <div class="wrap mb-1">
            <k-form-field>
              <k-select
                  class="w-100"
                  v-model="frequency"
                  @input="handleInputChange('frequency')"
                  :options="this.$options.frequencyOptions"
              />
            </k-form-field>
          </div>
        </div>
        <div class="row">
          <div class="label"><i class="fa-regular fa-paper-plane pr-1"></i>Send on</div>
          <div class="wrap">
            <k-select
                class="w-100"
                v-model="paymentDay"
                @input="handleInputChange('paymentDay')"
                :options="paymentDayOptions"
            />
          </div>
        </div>
        <div class="row">
          <div class="label"><i class="fa-regular fa-calendar-check pr-1"></i>First payment</div>
          <div class="wrap start-end-date">
            <date-input
                class="w-100"
                v-model="startDate"
                :min="minAutoPayDate"
                :show-flat-pickr="true"
                :enable-day="enableDay"
                :date-only="true"
                @input="handleInputChange('startDate')"
                ref="startDatePicker"
            />
          </div>
        </div>
        <div class="row">
          <div class="label"><i class="fa-regular fa-calendar-xmark pr-1"></i>Last payment</div>
          <div class="wrap start-end-date">
            <date-input
                class="w-100"
                v-model="endDate"
                :min="minAutoPayDate"
                :show-flat-pickr="true"
                :enable-day="enableDay"
                :date-only="true"
                @input="handleInputChange('endDate')"
                ref="endDatePicker"
            />
          </div>
        </div>
      </div>
      <div class="payments-count">
        <p>{{ paymentsCount }} total payments</p>
      </div>
    </box>
  </div>
</template>

<script>
import KSelect from '@components/inputs/k-select.vue';
import DateInput from '@components/inputs/date-input.vue';
import Box from '@components/box.vue';
import KFormField from '@components/forms/form_field.vue';
import KCheckbox from '@components/inputs/checkbox.vue';
import AutoPayMixin from '@mixins/autoPay';

export default {
  mixins: [AutoPayMixin],
  components: {
    Box,
    DateInput,
    KSelect,
    KFormField,
    KCheckbox,
  },
  props: {
    recurringSettings: Object,
    switchToLogin: Function,
    switchToRegister: Function,
    isAuthorized: Boolean,
  },
  data() {
    return {
      startDate: undefined,
      endDate: undefined,
      isRecurringPayment: false,
    };
  },
  computed: {
    paymentsCount() {
      if (!this.startDate || !this.endDate) {
        return 0;
      }
      let count = 0;
      if (this.frequency === 'monthly') {
        count = this.diffInMonths();
      } else if (this.frequency === 'biweekly') {
        count = this.diffInWeeks(2);
      } else {
        count = this.diffInWeeks(1);
      }
      return count;
    },
    isValid() {
      if (!this.isRecurringPayment) {
        return true;
      }
      if (this.startDate && this.endDate && this.startDate > this.endDate) {
        return false;
      }
      return !!this.frequency && !!this.paymentDay && !!this.startDate && !!this.endDate;
    },
  },
  methods: {
    onLogin() {
      this.$emit('switchToLogin');
    },
    onRegister() {
      this.$emit('switchToRegister');
    },
    handleInputChange(field) {
      if (['frequency', 'paymentDay'].includes(field)) {
        this.startDate = null;
        this.endDate = null;
        this.$refs.startDatePicker.date = null;
        this.$refs.endDatePicker.date = null;
      }
      this.$emit(
        'updateRecurringSettings',
        {
          frequency: this.frequency,
          payment_day: this.paymentDay,
          start_date: this.startDate,
          end_date: this.endDate,
          paymentsCount: this.paymentsCount,
          isValid: this.isValid,
          isRecurringPayment: this.isRecurringPayment,
        },
      );
    },
    diffInWeeks(step) {
      const MS_PER_WEEK = 1000 * 60 * 60 * 24 * 7;
      const startDate = new Date(this.startDate);
      const endDate = new Date(this.endDate);
      endDate.setDate(endDate.getDate() + (7 * step));
      const diffInMilliseconds = endDate - startDate;
      const diffInWeeks = diffInMilliseconds / MS_PER_WEEK;

      return Math.floor(diffInWeeks / step);
    },
    diffInMonths() {
      const startDate = new Date(this.startDate);
      const endDate = new Date(this.endDate);
      endDate.setMonth(endDate.getMonth() + 1);
      const yearsDifference = endDate.getFullYear() - startDate.getFullYear();
      const monthsDifference = endDate.getMonth() - startDate.getMonth();
      return yearsDifference * 12 + monthsDifference;
    },
  },
  mounted() {
    this.isRecurringPayment = this.recurringSettings.isRecurringPayment;
    this.frequency = this.recurringSettings.frequency || 'monthly';
    this.paymentDay = this.recurringSettings.payment_day || '1';
    this.startDate = this.recurringSettings.start_date;
    this.endDate = this.recurringSettings.end_date;

    if (this.$refs.startDatePicker) {
      this.$refs.startDatePicker.date = this.startDate;
    }
    if (this.$refs.endDatePicker) {
      this.$refs.endDatePicker.date = this.endDate;
    }
  },
};
</script>

<style lang="scss" scoped>
.recurring-payment {
  color: var(--periwinkle-80);
  border-radius: 8px 8px 0 0;
  margin-top: calc(var(--grid-unit) * 2);
}

.recurring-payment--selected {
  color: var(--periwinkle-80);
  border-radius: 8px;
  margin-top: calc(var(--grid-unit) * 2);
}

.recurring-settings {
  padding-left: calc(var(--grid-unit) * 2);
  padding-right: calc(var(--grid-unit) * 2);

  ::v-deep {
    .wrap select {
      background-color: white;
    }
  }

  .row {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 424px) {
      margin-right: 0;
      margin-left: 0;
    }
  }

  .wrap {
    min-width: calc(var(--grid-unit) * 30);
    margin-bottom:  calc(var(--grid-unit) * 2);
  }
}

.sign-up-text {
  color: var(--periwinkle-60);

  > a {
    color: var(--periwinkle-60);
    text-decoration: underline;
  }
}

.autopay-settings {
  border-radius: 0 0 8px 8px;
  color: var(--periwinkle-80);

  p {
    font-weight: 400;
    font-size: 16px;
    margin-bottom:  calc(var(--grid-unit) * 2);
  }

  .label {
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    padding-top: var(--grid-unit);
  }
}

.payments-count {
  display: flex;
  justify-content: end;
}
</style>
