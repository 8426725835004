<template>
  <div>
    <p v-if="!isAuthorized"
       class="login-text">Have a Kinside account?
      <a href="#" @click.prevent="switchToLogin">Sign in</a>
    </p>
    <k-form-field
        v-if="!isAuthorized"
        label="Email"
        class="form-field"
        :rules="{email: true, required: true}"
    >
      <k-input
          v-model.trim="email"
          class="w-100"
          placeholder="Enter your email address..."
          @change="validateInputs"
          :error="showErrors && !email"
      >
      </k-input>
    </k-form-field>

    <k-fieldset class="field-name"  v-if="!isAuthorized">
      <k-form-field
        label="First name"
        rules="required"
        class="form-field"
      >
        <k-input
          placeholder="First name..."
          v-model="firstName"
          @change="validateInputs"
          :error="showErrors && !firstName"
        />
      </k-form-field>
      <k-form-field
        label="Last name"
        rules="required"
        class="form-field"
      >
        <k-input
          placeholder="Last name..."
          v-model="lastName"
          @change="validateInputs"
          :error="showErrors && !lastName"
        />
      </k-form-field>
    </k-fieldset>
    <payment-request-basic-fields
      :totalAmount="totalAmount"
      :dependentId="dependentId"
      :isAuthorized="isAuthorized"
      :category="category"
      :otherCategory="otherCategory"
      :show-errors="showErrors"
      :child-info-missing="!hasChildInfo"
      @child-info-changed="setChildData"
      @basic-data-changed="setBasicData"
    />

    <recurring-payment-settings
      v-if="recurringSettings"
      :recurring-settings="recurringSettings"
      :email="email"
      :isAuthorized="isAuthorized"
      @updateRecurringSettings="updateRecurringSettings"
      @switchToLogin="switchToLogin"
      @switchToRegister="switchToRegister"
    />

    <parent-register
      :verify-email="email"
      :signup-from="'payment'"
      :verify-first-name="firstName"
      :verify-last-name="lastName"
      :skip-verify-account="true"
      @navigate-to-login="switchToLogin"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import KFieldset from '@components/forms/fieldset.vue';
import KFormField from '@components/forms/form_field.vue';
import KInput from '@components/inputs/input.vue';
import RecurringPaymentSettings from '@parent/components/payments/send-payment/recurring-payment-settings.vue';
import PaymentRequestBasicFields from '@components/payments/request-basic-fields.vue';
import ParentRegister from '@parent/components/register.vue';
import AuthMixin from '@profile/mixins/auth';

export default {
  components: {
    KFieldset,
    KFormField,
    KInput,
    RecurringPaymentSettings,
    PaymentRequestBasicFields,
    ParentRegister,
  },
  mixins: [AuthMixin],
  props: {
    providerId: {
      type: Number,
    },
    facilityId: {
      type: Number,
      required: true,
    },
    setSubmitDisabled: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      totalAmount: undefined,
      dependentId: undefined,
      category: undefined,
      otherCategory: undefined,
      email: undefined,
      firstName: undefined,
      lastName: undefined,
      recurringSettings: undefined,
      dependentData: {
        firstName: '',
        lastName: '',
        dateOfBirth: '',
      },
      showErrors: false,
    };
  },
  computed: {
    ...mapGetters({
      facility: 'facility/facilityInfo',
      currentPayment: 'payments/currentPayment',
      parentUser: 'user/currentParent',
    }),
    guest() {
      return !this.parentUser?.id || !!this.parentUser?.authEtc.guest;
    },
    isRecurringPayment() {
      return this.recurringSettings?.isRecurringPayment || false;
    },
    validateCategory() {
      return (this.category && this.category !== 'other') || (this.category && this.category === 'other' && !!this.otherCategory);
    },
    validAmount() {
      return this.totalAmount && this.totalAmount >= 50;
    },
    hasUserInfo() {
      return !this.guest || (!!this.email && !!this.firstName && !!this.lastName);
    },
    hasChildInfo() {
      return !!this.dependentId || (!!this.dependentData.firstName
             && !!this.dependentData.lastName
             && !!this.dependentData.dateOfBirth);
    },
  },
  methods: {
    setBasicData({ field, value }) {
      this[field] = value;
      this.validateInputs();
    },
    setChildData({ field, value }) {
      this.dependentData[field] = value;
      this.validateInputs();
    },
    validateInputs() {
      this.setSubmitDisabled(!this.recurringSettings?.isValid && this.isRecurringPayment);
    },
    updateRecurringSettings(value) {
      this.recurringSettings = value;
      this.validateInputs();
    },
    async onSubmit() {
      if (!this.validateCategory || !this.validAmount
          || !this.hasUserInfo || !this.hasChildInfo) {
        this.showErrors = true;
        this.setSubmitDisabled(true);
        return;
      }

      this.showErrors = false;
      this.setSubmitDisabled(false);
      let category;
      if (this.category === 'other') {
        category = this.otherCategory;
      } else {
        category = this.category;
      }

      if (!this.parentUser?.id) {
        const response = await this.$store.dispatch(
          'user/addUser',
          {
            email: this.email,
            firstName: this.firstName,
            lastName: this.lastName,
            parentSignup: true,
            signupFrom: 'payment',
          },
        );

        if (response && !response.success) {
          throw new Error();
        }
      } else if (this.guest && !!this.parentUser?.id) {
        if (this.parentUser.email !== this.email) {
          await this.$store.dispatch('user/updateNameEmail', { email: this.email, id: this.parentUser.id });
        }

        // eslint-disable-next-line max-len
        if (!!this.firstName && !!this.lastName && (this.parentUser.firstName !== this.firstName || this.parentUser.lastName !== this.lastName)) {
          this.$store.dispatch('user/updateNameEmail', { firstName: this.firstName, lastName: this.lastName, id: this.parentUser.id });
        }
      }
      const initialData = {
        amount: this.totalAmount,
        dependentId: this.dependentId,
        category,
        facilityId: Number(this.facilityId),
        providerId: this.providerId && Number(this.providerId),
        userId: this.parentUser.id,
        dependentData: this.dependentData,
        paymentRequestItems: [{ amount: this.totalAmount, fsa: false, text: this.category }],
        recurringSettings: this.isRecurringPayment ? this.recurringSettings : null,
      };
      await this.$store.dispatch('payments/setCurrentPayment', initialData);
    },
  },
  mounted() {
    this.totalAmount = this.currentPayment?.amount;
    this.dependentId = this.currentPayment?.dependent?.id;
    this.category = this.currentPayment?.category;
    this.email = this.parentUser?.email;
    this.firstName = this.parentUser.firstName || '';
    this.lastName = this.parentUser.lastName || '';
    this.recurringSettings = this.currentPayment?.recurringSettings || {};
  },
};
</script>

<style lang="scss" scoped>

.other-category {
  padding-left: calc(var(--grid-unit) * 3.5);
}

.login-text {
  font-size: calc(var(--grid-unit) * 2);
  font-weight: 500;
  margin-bottom: calc(var(--grid-unit) * 2);

  a {
    text-decoration: underline;
  }
}

.kFormField + .kFormField {
  margin-top: 0 !important;
}

.field-name {
  display: flex;
  gap: 16px;
}
</style>
