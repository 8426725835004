import {
  paymentMonthlyOptions, paymentWeeklyOptions, getWeekRanges,
  getBiWeekRanges, getMonthRanges,
} from '@utils';

export default {
  frequencyOptions: [
    {
      label: 'Monthly',
      value: 'monthly',
    },
    {
      label: 'Weekly',
      value: 'weekly',
    },
    {
      label: 'Every two weeks',
      value: 'biweekly',
    },
  ],
  data() {
    return {
      frequency: undefined,
      paymentDay: undefined,
    };
  },
  computed: {
    paymentDayOptions() {
      return this.frequency === 'monthly' ? paymentMonthlyOptions() : paymentWeeklyOptions();
    },
    enableDay() {
      return { frequency: this.frequency, day: this.paymentDay };
    },
    minAutoPayDate() {
      const today = new Date();
      const day = today.getDate();

      if (this.frequency === 'monthly') {
        if (day <= this.paymentDay) return today;

        // Get the first day of the next month
        return new Date(today.getFullYear(), today.getMonth() + 1, 1);
      }

      const interval = this.frequency === 'weekly' ? 7 : 14;
      const daysUntilNextPayment = (Math.abs(this.paymentDay - today.getDay()) + interval)
        % interval;
      return new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() + daysUntilNextPayment,
      );
    },
    billingDateRangeOptions() {
      const options = [''];
      if (!this.scheduledAt) {
        return [];
      }
      if (this.frequency === 'monthly') {
        options.push(...this.billingDateMonthlyOptions());
      } else if (this.frequency === 'weekly') {
        options.push(...this.billingDateWeeklyOptions());
      } else {
        options.push(...this.billingDateBiWeeklyOptions());
      }
      return options;
    },
  },
  methods: {
    handleInputChange(field) {
      if (['frequency', 'paymentDay'].includes(field)) {
        this.scheduledAt = null;
      }
      if (field === 'scheduledAt') {
        this.careFrom = null;
      }
      if (this.tuitionAmount && this.tuitionAmount > 0 && this.frequency && this.paymentDay
        && this.careFrom && this.scheduledAt) {
        this.disable = false;
      }
    },
    billingDateWeeklyOptions() {
      const weekRange = getWeekRanges(this.scheduledAt);
      const previousWeek = `${weekRange.previousWeek.start.toLocaleDateString()} - ${weekRange.previousWeek.end.toLocaleDateString()}`;
      const currentWeek = `${weekRange.currentWeek.start.toLocaleDateString()} - ${weekRange.currentWeek.end.toLocaleDateString()}`;
      const nextWeek = `${weekRange.nextWeek.start.toLocaleDateString()} - ${weekRange.nextWeek.end.toLocaleDateString()}`;
      return [
        {
          label: `Previous period (${previousWeek})`,
          value: weekRange.previousWeek.start.toLocaleDateString(),
        },
        {
          label: `Current period (${currentWeek})`,
          value: weekRange.currentWeek.start.toLocaleDateString(),
        },
        {
          label: `Next period (${nextWeek})`,
          value: weekRange.nextWeek.start.toLocaleDateString(),
        },
      ];
    },
    billingDateBiWeeklyOptions() {
      const weekRange = getBiWeekRanges(this.scheduledAt);
      const previousWeek = `${weekRange.previousBiweek.start.toLocaleDateString()} - ${weekRange.previousBiweek.end.toLocaleDateString()}`;
      const currentWeek = `${weekRange.currentBiweek.start.toLocaleDateString()} - ${weekRange.currentBiweek.end.toLocaleDateString()}`;
      const nextWeek = `${weekRange.nextBiweek.start.toLocaleDateString()} - ${weekRange.nextBiweek.end.toLocaleDateString()}`;
      return [
        {
          label: `Previous period (${previousWeek})`,
          value: weekRange.previousBiweek.start.toLocaleDateString(),
        },
        {
          label: `Current period (${currentWeek})`,
          value: weekRange.currentBiweek.start.toLocaleDateString(),
        },
        {
          label: `Next period (${nextWeek})`,
          value: weekRange.nextBiweek.start.toLocaleDateString(),
        },
      ];
    },
    billingDateMonthlyOptions() {
      const monthRange = getMonthRanges(this.scheduledAt);
      return [
        {
          label: `Previous period (${monthRange.prevMonth.from.toLocaleDateString()} - ${monthRange.prevMonth.to.toLocaleDateString()})`,
          value: monthRange.prevMonth.from.toLocaleDateString(),
        },
        {
          label: `Current period (${monthRange.currentMonth.from.toLocaleDateString()} - ${monthRange.currentMonth.to.toLocaleDateString()})`,
          value: monthRange.currentMonth.from.toLocaleDateString(),
        },
        {
          label: `Next period (${monthRange.nextMonth.from.toLocaleDateString()} - ${monthRange.nextMonth.to.toLocaleDateString()})`,
          value: monthRange.nextMonth.from.toLocaleDateString(),
        },
      ];
    },
    validRecurringItems() {
      return this.recurringLineItems
        && this.recurringLineItems.length > 0
        && this.recurringLineItems.every((item) => item.amount && item.text);
    },
  },
};
