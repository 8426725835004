<template>
  <div :class="wrapperClass">
    <system-button
      v-if="showAdditionalMethodButton"
      @click="openModal"
      data-cy="add-method-button"
      class="select-method__button"
      :color="color"
      :fa-icon="icon"
      :style-type="styleType || (this.selectedMethods.length === 1 ? 'secondary' : 'primary')"
      :class="{ 'select-method__button--extra-margin': !selectedMethods.length && !noExtraMargin}"
    >
      {{ selectMethodButtonLabel }}
    </system-button>
    <modal
      :is-open="isModalOpen"
      @close="closeModal"
    >
      <template #header>
        <h6>Select a payment method</h6>
      </template>
        <payment-method-card
          v-for="method in notSelectedPaymentMethods"
          :payment-method-id="method.id"
          :key="method.id"
          :editable="false"
          @click="onMethodAdded(method.id)"
          :disabled="isNaviaCardNoLongerValid(method.id)"
          :amount="providedAmount"
        />
      <add-payment-method
        class="select-method__modal__add-method"
        @method-added="onMethodAdded"
        @close="onAddMethodModalHide"
        :show-modal="notSelectedPaymentMethods && !notSelectedPaymentMethods.length"
        :payment-flow="paymentFlow"
      />
    </modal>
  </div>
</template>

<script>
import Modal from '@components/modal/modal.vue';
import SystemButton from '@components/buttons/system-button.vue';
import { mapGetters } from 'vuex';
import PaymentMethodCard from '../payment-method-card/payment-method-card.vue';
import AddPaymentMethod from '../add-payment-method/add-payment-method.vue';

export default {
  components: {
    Modal,
    SystemButton,
    PaymentMethodCard,
    AddPaymentMethod,
  },
  data() {
    return {
      isModalOpen: false,
      providedAmount: this.amount || '',
    };
  },
  props: {
    amount: {
      type: String,
      default: undefined,
    },
    paymentFlow: {
      type: String,
    },
    color: {
      type: String,
      default: 'periwinkle',
    },
    icon: {
      type: String,
    },
    buttonText: {
      type: String,
    },
    styleType: {
      type: String,
    },
    wrapperClass: {
      type: String,
      default: 'select-method',
    },
    noExtraMargin: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      paymentMethods: 'payments/paymentMethodsAsArray',
      currentPayment: 'payments/currentPayment',
    }),
    selectedMethods() {
      return (this.currentPayment.paymentRequestMethods || [])
        .map((payment) => payment.paymentMethodId);
    },
    notSelectedPaymentMethods() {
      return this.paymentMethods.filter((x) => !(['waitlist', 'seatOffer'].includes(this.paymentFlow) && x.fsa) && !this.selectedMethods.includes(x.id));
    },
    showAdditionalMethodButton() {
      return this.selectedMethods.length < 2;
    },
    selectMethodButtonLabel() {
      return this.buttonText || (this.selectedMethods.length === 1 ? '+ Add a second payment method' : 'Select your payment method');
    },
    isNaviaCardNoLongerValid() {
      const { navia, currentPayment } = this.$store.state.payments;
      return (id) => {
        if (!navia || !navia.cardId) {
          return false;
        }
        if (currentPayment.error?.relatedObj) {
          return id === currentPayment.error.relatedObj.id
            && (currentPayment.error.message || '').toLowerCase().includes('no longer active');
        }
        return navia.cardId === id && navia.error === 'no_longer_valid';
      };
    },
  },
  methods: {
    openModal() {
      this.isModalOpen = true;
    },

    closeModal() {
      this.isModalOpen = false;
    },

    onMethodAdded(paymentMethodId) {
      this.$emit('method-selected', paymentMethodId);
      this.closeModal();
    },

    onAddMethodModalHide() {
      if (!this.notSelectedPaymentMethods?.length) {
        this.closeModal();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.select-method {
  display: flex;
  flex-direction: column;

  &__button {
    align-self: center;

    &--extra-margin {
      margin-top: calc(var(--grid-unit) * 2);
      margin-bottom: calc(var(--grid-unit) * 2);
    }
  }
}

</style>
