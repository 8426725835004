<template>
    <div class="page__content no-bg">
      <box color="gold mb-3 align-center">
        <p class="info"> This is what families will see when you share this link.</p>
      </box>
      <payment-link-flow :show-email-input="true" :facility="facility" />
    </div>
</template>

<script>

import PaymentLinkFlow from '@care-provider/components/payments/payment-link-flow.vue';
import Box from '@components/box.vue';

export default {
  components: {
    PaymentLinkFlow,
    Box,
  },
  props: {
    facility: Object,
  },
};
</script>

<style lang="scss" scoped>
 .no-bg {
   background: none !important;
 }

 .box {
   border: 1px solid var(--gold-50);
 }

 .info {
   font-size: calc(var(--grid-unit) * 2);
   font-weight: 400;
   margin-bottom: 0;
 }
</style>
