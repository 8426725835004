<template>
  <div>
    <section>
      <h2 v-if="showAmount" class="amount font-heading-40 align-center">
        {{ displayInDollars(totalAmount) }}
      </h2>
      <div v-if="currentPayment.paymentRequestMethods.length > 0">
        <hr/>
        <h6 class="title font-heading-10">Payment method</h6>
        <p class="method-desc">
          You can select up to two payment methods. If you are using an FSA,
          you may need to select a second payment method to cover the total balance.
        </p>
      </div>

      <payment-method-card
          v-for="method in currentPayment.paymentRequestMethods"
          :key="'payment-method__' + method.paymentMethodId"
          v-bind="method"
          :show-amount="true"
          v-model.number="method.totalAmount"
      />

      <select-payment-method
          v-if="currentPayment.paymentRequestMethods.length < 2"
          payment-flow="send-payment"
          :color="selectMethodButtonStyle.color"
          :icon="selectMethodButtonStyle.icon"
          :button-text="selectMethodButtonStyle.text"
          :style-type="selectMethodButtonStyle.styleType"
          :wrapper-class="selectMethodButtonStyle.wrapperClass"
          :noExtraMargin="true"
          @method-selected="addPaymentMethod"
      />

      <template v-if="shouldShowRemaining">
        <hr/>
        <table class="send-payment__summary-table">
          <tr>
            <td>Remaining balance</td>
            <td class="send-payment__remainder">
              {{ displayInDollars(totalRemainingWithFee) }}
            </td>
          </tr>
        </table>
      </template>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getDollarFormat } from '@utils';
import SelectPaymentMethod from './select-payment-method.vue';
import PaymentMethodCard from '../payment-method-card/payment-method-card.vue';

export default {
  components: {
    SelectPaymentMethod,
    PaymentMethodCard,
  },
  props: {
    setSubmitDisabled: {
      type: Function,
      required: true,
    },
    showAmount: {
      type: Boolean,
      default: true,
    },
    selectColor: {
      type: String,
    },
  },
  data() {
    return {
      showSendPaymentModal: false,
    };
  },
  watch: {
    selectedMethodsLength(newVal) {
      this.setSubmitDisabled(newVal < 1);
    },
    totalRemainingWithFee(newVal) {
      this.setSubmitDisabled(newVal > 0);
    },
  },
  computed: {
    ...mapGetters({
      currentPayment: 'payments/currentPayment',
      totalRemainingWithFee: 'payments/currentPaymentTotalRemainingWithFee',
      paymentMethods: 'payments/paymentMethodsAsArray',
    }),

    totalAmount: {
      get() {
        return this.currentPayment.amount;
      },
      set(amount) {
        this.$store.dispatch('payments/setTotalAmount', amount);
      },
    },

    shouldShowRemaining() {
      return this.totalRemainingWithFee
          && this.currentPayment.paymentRequestMethods.length > 0;
    },

    selectedMethodsLength() {
      return this.currentPayment.paymentRequestMethods.length;
    },
    selectMethodButtonStyle() {
      const color = this.selectColor ? this.selectColor : 'periwinkle';
      if (this.currentPayment.paymentRequestMethods.length === 0) {
        return { color, icon: '' };
      }

      return {
        color: 'viridian', icon: 'plus', text: 'Add another payment option', styleType: 'clear', wrapperClass: '',
      };
    },
  },
  methods: {
    displayInDollars: getDollarFormat,

    addPaymentMethod(paymentMethodId, desiredAmount) {
      this.$store.dispatch('track/event', {
        category: 'payments',
        event: 'new_payment_add_payment_row',
      });

      let defaultAmount = this.currentPayment.totalRemaining; // minimum charge amount
      if (this.currentPayment.totalRemaining === 0) {
        defaultAmount = this.totalAmount / 2;
      }

      let amountToApply = Math.abs(desiredAmount) || Math.abs(defaultAmount);
      if (amountToApply > this.totalAmount) {
        amountToApply = this.totalAmount;
      }

      this.$store.dispatch('payments/updateMethodInCurrentPayment', {
        paymentMethodId,
        totalAmount: amountToApply,
        includesFees: !!desiredAmount,
      });
    },
  },
  mounted() {
    this.setSubmitDisabled(this.selectedMethodsLength < 1);
    // auto select a payment method if no method is selected
    // and the parent has only one payment method linked to their account.
    if (this.paymentMethods.length === 1 && !this.currentPayment.paymentRequestMethods.length) {
      this.addPaymentMethod(this.paymentMethods[0].id);
    }
  },
};
</script>

<style lang="scss" scoped>

.send-payment {
  .amount {
    margin-bottom: calc(var(--grid-unit) * 3);
  }

  .title {
    margin-top: calc(var(--grid-unit) * 3);
    font-family: var(--sans-serif);
  }

  .method-desc {
    color: #616161;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
  }

  .method-options {
    display: block;
  }

  &__remainder {
    color: var(--rust-50);
  }

  &__summary-table {
    width: 100%;
    margin-left: auto;
    color: var(--gray-80);

    td {
      padding-left: calc(var(--grid-unit) * 2);

      &:last-child {
        position: relative;
        width: 110px;
        padding-right: calc(var(--grid-unit) * 2);
        text-align: right;
      }

      &::before {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
      }
    }
  }
}
</style>
