<template>
  <div>
    <div class="dependents" v-if="!isProvider && isAuthorized">
      <h6> Which child are you paying for? </h6>
      <dependents-radio-list
        :parentId="parentUser?.id"
        :modelValue="dependentId"
        :showManage="!isProvider"
        @change="(e) => emitInputChange('dependentId', e)"
        />
      <div class="mt-1 error-message" v-if="showErrors && childInfoMissing">
        Please add or select a child to continue.
      </div>
    </div>
    <template v-if="!isProvider && !isAuthorized">
      <payment-request-new-child-fields
        @child-info-changed="onChildInfoChanged"
        :show-errors="showErrors"
      />
      <div class="mt-1 error-message" v-if="showErrors && childInfoMissing">
        Please add a child to continue.
      </div>
    </template>
    <k-form-field
      :label="isProvider ? 'Payment amount' : 'How much would you like to send?'"
      :class="isProvider ? '' : 'mt-2'"
    >
      <currency-input
        :value="totalAmount"
        rules="required"
        @change="(e) => emitInputChange('totalAmount', e)"
        :error="showErrors && !totalAmount"
      />
      <div class="mt-1 error-message" v-if="showErrors && !totalAmount">
        Please add an amount to continue.
      </div>

    </k-form-field>
    <k-form-field label="What is this payment for?">
      <k-radio
        @change="(e) => onCategoryChange(e)"
        :value="'deposit'"
        :modelValue="category"
        name="category"
      >
        Deposit/registration
      </k-radio>
      <k-radio
        @change="(e) => onCategoryChange(e)"
        :value="'tuition'"
        :modelValue="category"
        name="category"
      >
        Tuition
      </k-radio>
      <k-radio
        @change="(e) => onCategoryChange(e, 'category')"
        class="other"
        :value="'other'"
        :modelValue="category"
      >
        Other
      </k-radio>
      <div class="other-category">
        <k-input
          v-if="this.category === 'other'"
          name="category"
          :value="otherCategory"
          scale="small"
          maxlength="20"
          :small-label="true"
          @input="(e) => emitInputChange('otherCategory', e? e.trim() : e)"
          label="Give a short description like “materials” or “field trip”"
        />
      </div>
      <div class="mt-1 error-message" v-if="showErrors && !category">
        Please add a category to continue.
      </div>
    </k-form-field>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DependentsRadioList from '@parent/components/dependent-management/dependents-radio-list.vue';
import PaymentRequestNewChildFields from '@components/payments/new-child-fields.vue';
import CurrencyInput from '@components/inputs/currency_input.vue';
import KFormField from '@components/forms/form_field.vue';
import KRadio from '@components/inputs/radio.vue';
import KInput from '@components/inputs/input.vue';

export default {
  name: 'payment-request-basic-fields',

  props: {
    isProvider: { type: Boolean },
    isAuthorized: { type: Boolean },
    totalAmount: { type: Number },
    dependentId: { type: Number },
    category: { type: String },
    otherCategory: { type: String },
    showErrors: { type: Boolean },
    childInfoMissing: { type: Boolean },
  },

  components: {
    DependentsRadioList,
    PaymentRequestNewChildFields,
    KRadio,
    CurrencyInput,
    KFormField,
    KInput,
  },
  computed: {
    ...mapGetters({ parentUser: 'user/currentParent' }),
  },

  methods: {
    onCategoryChange(e) {
      this.emitInputChange('category', e);
      if (e !== 'other') {
        this.emitInputChange('otherCategory', undefined);
      }
    },
    emitInputChange(field, value) {
      if (this[field] === value) return;
      this.$emit('basic-data-changed', { field, value });
    },
    onChildInfoChanged({ field, value }) {
      this.$emit('child-info-changed', { field, value });
    },
  },
};
</script>

<style lang="scss" scoped>
.dependents {
  margin-top: calc(var(--grid-unit));
  margin-bottom: calc(var(--grid-unit) * 2);
}

.error-message {
  color: var(--rust-50);
}
</style>
