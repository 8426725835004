<template>
  <div :class="`child-fields${ hasborder ? '__border' : ''}`">
    <hr v-if="!hasborder">
    <h6 class="title font-heading-10 mg-b-24">CHILD INFO</h6>
    <k-fieldset class="fields" :class="{'fields__border': hasborder}">
      <k-form-field
        label="First name"
        rules="required"
        class="form-field"
        >
        <k-input
          v-model.trim="firstName"
          @change="$emit('child-info-changed', { field: 'firstName', value: firstName })"
          :error="showErrors && !firstName"
        />
      </k-form-field>
      <k-form-field
        label="Last name"
        rules="required"
        class="form-field"
        >
        <k-input
          v-model.trim="lastName"
          @change="$emit('child-info-changed', { field: 'lastName', value: lastName })"
          :error="showErrors && !lastName"
        />
      </k-form-field>
      <k-form-field label="Birth date">
        <k-date-input
          class="family-info__date-input"
          v-model="dateOfBirth"
          :date-only=true
          required
          @input="$emit('child-info-changed', { field: 'dateOfBirth', value: dateOfBirth })"
          :error="showErrors && !dateOfBirth"
        />
      </k-form-field>
    </k-fieldset>
  </div>
</template>

<script>
import KFormField from '@components/forms/form_field.vue';
import KFieldset from '@components/forms/fieldset.vue';
import KInput from '@components/inputs/input.vue';
import KDateInput from '@components/inputs/date-input.vue';

export default {
  name: 'payment-request-new-child-fields',

  props: {
    dependentData: { type: Object, required: false },
    hasborder: { type: Boolean, default: false },
    showErrors: { type: Boolean, default: false },
  },

  components: {
    KFieldset,
    KFormField,
    KInput,
    KDateInput,
  },

  data() {
    return {
      firstName: '',
      lastName: '',
      dateOfBirth: undefined,
    };
  },

  mounted() {
    if (this.dependentData) {
      this.firstName = this.dependentData.firstName;
      this.lastName = this.dependentData.lastName;
      this.dateOfBirth = this.dependentData.dateOfBirth;
    }
  },
};
</script>

<style scoped lang="scss">
.kFormField + .kFormField {
  margin-top: 0 !important;
}

.child-fields {
  &__border {
    border: 1px solid #dbdbdb;
    border-radius: var(--radius-big);
    padding: calc(var(--grid-unit) * 3);
  }

  .title {
    color: var(--gray-60);
    font-family: var(--sans-serif);
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    margin: 0 0 calc(var(--grid-unit) * 3);
  }

  &__name {
    display: flex;
    gap: 16px;
  }
}

.mg-b-8 {
  margin-bottom: calc(var(--grid-unit));
}

.fields {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: calc(var(--grid-unit) * 2);
  margin-bottom: calc(var(--grid-unit) * 2);

  ::v-deep {
    .kFormField {
      margin-bottom: 0;
    }

    .kFormField + .kFormField {
      margin-top: 0;
    }
  }

  @media (min-width: 768px) {
    grid-template-columns: 0.8fr 1fr 0.8fr;
  }
}

.fields__border {
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
