<template>
  <main
      class="page send-payment page--narrow"
      :class="{'pt-2': !isParent}"
  >
    <div v-if="isParent || !isFacilityProvider" class="page__content">
      <header class="page__header">
        <div><i :class="showStatus ? 'fal fa-check-circle' : 'fal fa-usd-circle'"></i>
          {{ headerTitle }}
        </div>
        <steps
            v-if="!showStatus"
            :current-step="navStepsCurrent"
            :total-steps="navStepsTotal"
        />
      </header>
      <status-notification
          v-if="showStatus"
      />
      <section v-else class="page__body">
        <template v-if="facility">
          <simple-facility-card
              :key="facility.id"
              :facility-data="facility"
              :is-summary="false"
              :is-claimable="false"
              :is-editable="false"
              class="facility-card"
          />
          <component
              :is="currentStepComponent"
              ref="currentStepComponentRef"
              v-bind="currentProperties"
              :setLoading="setLoading"
          />
          <hr/>
          <div class="align-center">
            <system-button
                v-if="currentStep > 0"
                :style-type="'secondary'"
                :disabled="currentStep < 1"
                @click="onPrevStep"
            >
              {{ prevButtonText() }}
            </system-button>
            <system-button
                @click="onNextStep"
                class="ml-2"
                :disabled="nextStepDisabled || loading || !enableSendPayment"
            >
              {{ nextButtonText() }}
            </system-button>
          </div>

          <div v-if="isConfirmationStep" class="secure-connection">
            <span>
              <i class="fa far fa-lock"></i>
              <span>Secure connection</span>
            </span>
          </div>
        </template>
      </section>
      <template v-if="loading">
        <loader :loading="loading" />
      </template>

      <opted-out-parent-preview
          :open-modal="!enableSendPayment"
      ></opted-out-parent-preview>
    </div>
    <div v-else-if="!enableSendPayment">
      <opted-out-provider-preview :facility="facility"></opted-out-provider-preview>
    </div>
    <div v-else>
      <opted-in-provider-preview :facility="facility"></opted-in-provider-preview>
    </div>
  </main>
</template>

<script>
import { mapGetters } from 'vuex';
import Loader from '@components/loader/loader.vue';
import Steps from '@components/progress/steps.vue';
import SystemButton from '@components/buttons/system-button.vue';
import PaymentMethodForm from '@parent/components/payments/send-payment/payment-method-form.vue';
import BasicInfo from '@parent/components/payments/send-payment/basic-info.vue';
import BasicInfoRequest from '@parent/components/payments/send-payment/basic-info-request.vue';
import Checkout from '@parent/components/payments/send-payment/checkout.vue';
import SimpleFacilityCard from '@components/simple-facility-card/simple-facility-card.vue';
import StatusNotification from '@parent/components/payments/send-payment/status-notification.vue';
import AuthMixin from '@profile/mixins/auth';
import OptedOutParentPreview from '@parent/components/payments/send-payment/opted-out-parent-preview.vue';
import OptedOutProviderPreview from '@parent/components/payments/send-payment/opted-out-provider-preview.vue';
import OptedInProviderPreview from '@parent/components/payments/send-payment/opted-in-provider-preview.vue';

export default {
  name: 'pay-me-flow',
  components: {
    SimpleFacilityCard,
    Loader,
    Steps,
    BasicInfo,
    BasicInfoRequest,
    PaymentMethodForm,
    Checkout,
    SystemButton,
    StatusNotification,
    OptedOutParentPreview,
    OptedOutProviderPreview,
    OptedInProviderPreview,
  },
  mixins: [AuthMixin],
  data() {
    return {
      currentStep: 0,
      loading: false,
      nextStepDisabled: false,
      paymentData: {},
      enableSendPayment: true,
      showErrors: false,
    };
  },
  computed: {
    ...mapGetters({
      facility: 'facility/facilityInfo',
      currentPayment: 'payments/currentPayment',
      user: 'user/currentParent',
      payMeRequest: 'payments/payMeRequest',
    }),
    stepsData() {
      return [
        {
          component: `${this.isPayMeRequest ? 'BasicInfoRequest' : 'BasicInfo'}`,
          stepName: 'BasicInfo',
          prevButtonText: 'Back',
          nextButtonText: 'Continue',
        },
        {
          component: 'PaymentMethodForm',
          stepName: 'PaymentMethodForm',
          prevButtonText: 'Back',
          nextButtonText: 'Continue',
        },
        {
          component: 'Checkout',
          stepName: 'Checkout',
          prevButtonText: 'Back',
          nextButtonText: 'Confirm & send',
        },
      ];
    },
    isPayMeRequest() {
      return !!this.payMeRequest?.id && this.payMeRequest?.aasmState === 'requested';
    },
    isParent() {
      return !this.user || this.user.isParent;
    },
    isFacilityProvider() {
      return this.user.isProvider
          && this.facility?.provider_id === this.$store.state.user.current.providerId;
    },
    totalSteps() {
      return Object.keys(this.stepsData).length;
    },
    stepName() {
      return this.stepsData[this.currentStep].stepName;
    },
    navStepsTotal() {
      return this.totalSteps;
    },
    navStepsCurrent() {
      switch (this.stepName) {
        case 'BasicInfo':
          return 1;
        case 'PaymentMethodForm':
          return 2;
        case 'Checkout':
          return 3;
        default:
          return this.currentStep;
      }
    },
    currentStepComponent() {
      return this.stepsData[this.currentStep]?.component;
    },
    currentProperties() {
      return {
        facilityId: this.facility?.id,
        providerId: this.facility?.provider_id,
        setSubmitDisabled: this.setNextStepDisabled,
        clickOnPrevStep: this.onPrevStep,
      };
    },
    isConfirmationStep() {
      return (this.currentStep + 1) === this.totalSteps;
    },
    showStatus() {
      return !this.isAuthorized && this.currentPayment && ['payin_bank_transactions_inititated', 'payin_all_transactions_inititated'].includes(this.currentPayment.status);
    },
    headerTitle() {
      return this.showStatus ? 'Payment sent!' : 'SEND PAYMENT';
    },
  },
  methods: {
    prevButtonText() {
      return this.stepsData[this.currentStep]?.prevButtonText || 'Back';
    },
    nextButtonText() {
      return this.stepsData[this.currentStep]?.nextButtonText || 'Continue ';
    },
    setNextStepDisabled(disabled) {
      this.nextStepDisabled = disabled;
    },
    setLoading(value) {
      this.loading = value;
    },
    onPrevStep() {
      if (this.currentStep === 0) {
        return;
      }
      this.nextStepDisabled = false;
      this.currentStep -= 1;
    },
    async onNextStep() {
      if (!this.enableSendPayment) {
        return;
      }
      if (typeof (this.$refs.currentStepComponentRef.onSubmit) === 'function') {
        this.loading = true;
        try {
          await this.$refs.currentStepComponentRef.onSubmit();
          // This check is here incase the step component is preventing
          // the submission action from completing by setting the nextStepDisabled
          if (this.nextStepDisabled) {
            if (this.currentStep === 0) this.setNextStepDisabled(false);
            return;
          }
        } catch (e) {
          this.loading = false;
          this.setNextStepDisabled(true);
          throw (e);
        } finally {
          this.loading = false;
        }
      }

      window.scrollTo({ top: 0, behavior: 'smooth' });
      if (!this.isConfirmationStep) {
        this.currentStep += 1;
        return;
      }

      if (this.isAuthorized) {
        this.$router.replace(
          {
            path: '/payments/',
            query: { send: true },
          },
        );
      }
    },
  },
  async mounted() {
    this.loading = true;
    await this.$store.dispatch(
      'facility/fetchFacilityDetails',
      `${this.$route.params.state}/${this.$route.params.city}/${this.$route.params.facilityFriendlyId}`,
    );

    if (this.$route.query.pr_id) {
      await this.$store.dispatch(
        'payments/fetchPayMeRequest',
        { id: this.$route.query.pr_id, facilityId: this.facility?.id },
      );
    }

    if (!this.facility.enable_send_payment) {
      this.enableSendPayment = false;
    }
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>

.page {
  --max-page-width: 720px;

  &.send-payment {
    padding-top: var(--grid-unit);
  }

  &--narrow {
    --max-page-width: 490px;
  }

  &:last-of-type {
    margin-bottom: calc(var(--grid-unit) * 15);

    @media screen and (max-width: 768px) {
      margin-bottom: calc(var(--grid-unit));
    }
  }

  &__content {
    max-width: var(--max-page-width);
    min-height: 100%;
    margin: 0;
    background: var(--gray-0);
    font-size: calc(var(--grid-unit) * 2);

    @media screen and (min-width: 768px) {
      min-height: 300px;
      margin: calc(var(--grid-unit) * 5) auto;
      border-radius: calc(var(--grid-unit) * 4);
    }

    @media screen and (max-width: 768px) {
      width: 100vw;
      padding-bottom: calc(var(--grid-unit) * 5);
    }
  }

  &__header {
    display: revert;
    color: var(--viridian-0);
    text-align: center;
    text-transform: uppercase;
    background: var(--viridian-50);
    padding: calc(var(--grid-unit) * 2);
    font-weight: 500;

    @media screen and (min-width: 400px) {
      padding: calc(var(--grid-unit) * 3);
    }

    @media screen and (min-width: 768px) {
      border-radius: calc(var(--grid-unit) * 4) calc(var(--grid-unit) * 4) 0 0;
    }

    .fal {
      margin-right: var(--grid-unit);
      font-weight: 500;
    }

    > * {
      margin-bottom: 0;
    }
  }

  &__body {
    padding: calc(var(--grid-unit) * 2);

    @media screen and (min-width: 400px) {
      padding: calc(var(--grid-unit) * 3);
    }

    @media screen and (min-width: 768px) {
      padding: calc(var(--grid-unit) * 6);
      padding-bottom: calc(var(--grid-unit) * 3);
    }

    > * {
      margin-bottom: calc(var(--grid-unit) * 2);

      @media screen and (min-width: 400px) {
        margin-bottom: calc(var(--grid-unit) * 3);
      }
    }
  }

  .secure-connection {
    color: #616161;
    font-size: calc(var(--grid-unit) * 2);
    text-align: center;
  }
}
</style>
